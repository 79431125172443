import { defineStore } from "pinia";
import { capitalize } from "vue";

export const useTableColumnStore = defineStore("TableColumnStore", {
  state: () => ({
    columns: [
      { key: 'id', label: 'Select', sortable: false, alwaysVisible: true },
      { key: 'cover', label: 'Cover', sortable: false, alwaysVisible: true },
      { key: 'title', label: 'Title & Subtitle', sortable: true, alwaysVisible: true },
      { key: 'publication', label: 'Publication date', sortable: true },
      { key: 'author', label: 'Author(s)', sortable: true },
      { key: 'publisher', label: 'Publisher', sortable: true },
      { key: 'collection', label: 'Collection', sortable: true },
      { key: 'category', label: 'Categories', sortable: true },
      { key: 'influence', label: 'Influences', sortable: true },
      { key: 'isbn', label: 'ISBN', sortable: true },
      { key: 'last_updated', label: 'Last updated', sortable: true },
      { key: 'book_format', label: 'Format' },
      { key: 'lists', label: 'Lists' },
      { key: 'series', label: 'Series' },
      { key: 'from_quebec', label: 'From Quebec' }
    ],
    activeColumns: [
      'id', 
      'cover', 
      'title', 
      'publication', 
      'author', 
      'publisher', 
      'collection', 
      'category',
      'influence', 
      'isbn', 
      'last_updated', 
      'book_format', 
      'lists', 
      'series', 
      'from_quebec'
    ],
    inactiveColumns: [],
    orderColumns: [
      'id', 
      'cover', 
      'title', 
      'publication', 
      'author', 
      'publisher', 
      'collection', 
      'category',
      'influence', 
      'isbn', 
      'last_updated', 
      'book_format', 
      'lists', 
      'series', 
      'from_quebec'
    ],

    filters: [
      { key: 'publication_date' },
      { key: 'catalog_date' },
      { key: 'provider' },
      { key: 'language' },
      { key: 'availability' },
      { key: 'book_format' },
      { key: 'catalogued' },
      { key: 'category' },
      { key: 'influence' },
      { key: 'audience' },
      { key: 'author' },
      { key: 'imprint' },
      { key: 'collection' },
      { key: 'catalog_type' },
      { key: 'title_subtitle' },
      { key: 'isbn' },
      { key: 'from_quebec' },
      { key: 'cover' },
    ],
    activeFilters: [
      'publication_date',
      'catalog_date',
      'provider',
      'language',
      'availability',
      'book_format',
      'catalogued',
      'category',
      'influence',
      'audience',
      'author',
      'imprint',
      'collection',
      'catalog_type',
      'title_subtitle',
      'isbn',
      'from_quebec',
      'cover'
    ],
    inactiveFilters: [],
    orderFilters: [
      'publication_date',
      'catalog_date',
      'provider',
      'language',
      'availability',
      'book_format',
      'catalogued',
      'category',
      'influence',
      'audience',
      'author',
      'imprint',
      'collection',
      'catalog_type',
      'title_subtitle',
      'isbn',
      'from_quebec',
      'cover'
    ]
  }),
  getters: {
    getColumnsCanBeHidden() {
      return this.columns.filter(column => !column.alwaysVisible);
    },
    getFiltersCanBeHidden() {
      return this.filters.filter(filter => !filter.alwaysVisible);
    }
  },
  actions: {
    removeOrAdd(item, type) {
      const active = `active${capitalize(type)}`;
      const inactive = `inactive${capitalize(type)}`;
      if(this[active].includes(item.key)) {
        const index = this[active].indexOf(item.key);
        this[active].splice(index, 1);
        this[inactive].push(item.key);
      } else {
        const index = this[inactive].indexOf(item.key);
        this[inactive].splice(index, 1);
        this[active].push(item.key);
      }
    },
    
    getOriginals(type) {
      return this[type].map(item => item.key);
    },

    reset(type) {
      const active = `active${capitalize(type)}`;
      const inactive = `inactive${capitalize(type)}`;
      this[active].splice(0, this[active].length);
      this[active].push(...this.getOriginals(type));
      this[inactive].splice(0, this[inactive].length);
    },
    
    changePositions(item, newPosition, type) {
      const order = `order${capitalize(type)}`;
      const index = this[order].indexOf(item);
      this[order].splice(newPosition, 0, this[order].splice(index, 1)[0]);
    },

    applyOrderAndVisibility(type) {
      const active = `active${capitalize(type)}`;
      const order = `order${capitalize(type)}`;
      const newOrder = this[order].filter((column) => {
        return this[active].includes(column) ? column : null;
      });
      this[active].splice(0, this[active].length);
      this[active].push(...newOrder.filter(Boolean));
    }
    
  }
});
