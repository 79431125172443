<script setup>

  import { computed, ref } from 'vue';
  import { useTableColumnStore } from '../../stores/TableColumnStore.js';
  import { usePublicationsStore } from '../../stores/PublicationStore.js';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';
  import { BAlert, BButton, BProgress } from 'bootstrap-vue-next';

  const tableColumnStore = useTableColumnStore();
  const publicationsStore = usePublicationsStore();
  const { columns } = storeToRefs(tableColumnStore);
  const { selectedItems } = storeToRefs(publicationsStore);
  const { requestExport } = usePublicationsStore();
  const emits = defineEmits(['close']);
  const { t } = useI18n();

  const inactiveColumns = ref([]);
  const pageText = ref({
    title: t('store_item_export.component.title'),
    description: t('store_item_export.component.description'),
    export_buttons_description: t('store_item_export.component.export_buttons_description'),
    label: t('store_item_export.component.label'),
    confirm: t('store_item_export.component.buttons.confirm'),
    cancel: t('store_item_export.component.buttons.cancel'),
  });

  const validColumns = computed(() => {
    return columns.value.filter(column => column.key !== 'id');
  });

  const addOrRemoveColumn = (key) => {
    if(inactiveColumns.value.includes(key)) {
      inactiveColumns.value = inactiveColumns.value.filter(column => column !== key);
    } else {
      inactiveColumns.value = [...inactiveColumns.value, key];
    };
  };

  const columnsToExport = computed(() => {
    return validColumns.value.filter(column => !inactiveColumns.value.includes(column.key));
  });
  

  const removedColumns = computed(() => {
    return validColumns.value.filter(column => inactiveColumns.value.includes(column.key));
  });

  const errorMessage = ref(null);
  const dismissCountDown = ref(0);
  const countDownSettingsMs = ref({
    success: 8000,
    error: 12000
  });
  const countdown = ref(0);
  const alertContent = ref({
    message: '',
    variant: 'success',
  });
  const disableButtons = ref(false);
  const closeAlert = (event) => {
    countdown.value = event;
    if(event !== 0) { return; };

    dismissCountDown.value = 0;
    
    if(errorMessage.value === null)  { emits('close'); };
  };

  const displayAlert = computed(() => {
    return dismissCountDown.value > 0;
  });

  const submit = async () => {
    errorMessage.value = null;
    disableButtons.value = true;

    const columns = columnsToExport.value.map(column => column.key);
    const urlParams = new URLSearchParams(window.location.search);
    const objParams = {
      urlParams: urlParams,
      payload: {
        store_item: {
          ids: selectedItems.value,
          columns: columns
        }
      }
    };
    const response = await requestExport(objParams);
    const { data, status } = response;
    if(status === 201) {
      dismissCountDown.value = countDownSettingsMs.value.success;
      alertContent.value = {
        message: data.message,
        variant: 'success',
      };
    } else {
      dismissCountDown.value = countDownSettingsMs.value.error;
      errorMessage.value = data.message;
      alertContent.value = {
        message: data.message,
        variant: 'danger',
      };
    }
    disableButtons.value = false;
  };
</script>
<template>
  <div class="col-6 offset-3 d-flex justify-content-center align-items-center" v-if="displayAlert">
    <BAlert
        v-model="dismissCountDown"
        dismissible
        :variant="alertContent.variant"
        @close-countdown="closeAlert($event)"e
        class="w-100"
      >
      <p>{{ alertContent.message }}</p>
      <BProgress :variant="alertContent.variant" :max="dismissCountDown" :value="countdown" height="4px" />
    </BAlert>
  </div>
  <div class="col-6 offset-3" v-else>
    <div class="row text mb-3">
      <h1>{{ pageText.title }}</h1>
      <p class="mb-4">
        {{ pageText.description }}<br>
        <span class="text-primary">{{ pageText.export_buttons_description }}</span>
      </p>
      <h3>{{ pageText.label }}</h3>
    </div>
    <div class="row">
      <ul>
        <li v-for="column in columnsToExport" :key="column.key">
          <BButton  class="btn-active" @click="addOrRemoveColumn(column.key)" variant="primary">
            <i class="bi bi-eye-fill"></i>
            <i class="bi bi-eye-slash-fill"></i>
            {{ t(`store_item_export.columns.${column.key}`) }}
          </BButton>
        </li>
      </ul>
    </div>
    <div class="row">
      <ul class="mt-5">
        <li v-for="column in removedColumns" :key="column.key">
          <BButton class="btn-inactive" @click="addOrRemoveColumn(column.key)" variant="outline-primary">
            <i class="bi bi-eye-fill"></i>
            <i class="bi bi-eye-slash-fill"></i>
            {{ t(`store_item_export.columns.${column.key}`) }}
          </BButton>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <BButton variant="danger" size="lg" @click.prevent="emits('close')" :disabled="disableButtons">
          {{ pageText.cancel }}
        </BButton>
        <BButton :disabled="columnsToExport.length === 0 || disableButtons"  variant="success" class="ms-3" size="lg" @click="submit">
          {{ pageText.confirm }}
        </BButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  :deep(){
    .btn-active {
      .bi-eye-slash-fill {
        display: none;
      }
      &:hover {
        background-color: #fff !important;
        color: #0d6efd !important;
        .bi-eye-fill {
          display: none;
        }
        .bi-eye-slash-fill {
          display: inline;
        }
      }
    }
    .btn-inactive {
      .bi-eye-fill {
        display: none;
      }
      &:hover {
        .bi-eye-slash-fill {
          display: none;
        }
        .bi-eye-fill {
          display: inline;
        }
      }
    }
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    li {
      margin: 5px;
    }
  }
</style>
