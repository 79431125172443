const MULTISELECT_LEFT_MARGIN = 600;
const ALL_VARIATIONS = ['All', 'all', 'Tous', 'Todos', 'Tutti'];
let timer = null;
const selectorsIds = [
  'categorySelector',
  'librarySelector',
  'languageSelector',
  'authorSelector',
  'imprintSelector',
  'collectionsSelector'
];

// Debouncer:
const DEBOUNCE_TIME = 1300;
const MIN_TEXT_LENGTH = 2;
let debounceRunning = false;

export const cleanUpFields = function(params, condition = null) {
  let cleanParams = {};
  if (Object.keys(params).length !== 0) {
    for (let key in params) {
      if (params[key] !== condition && params[key] !== '') {
        if (Array.isArray(params[key])) {
          if (params[key].length > 0) {
            const value = params[key][Object.keys(params[key])[0]];
            if (value !== 'All') {
              cleanParams[key] = value;
            }
          }
        } else {
          cleanParams[key] = params[key];
        }
      }
    }
  }

  return cleanParams;
}

export const mapInfluences = function(influences) {
  const all_influences = [];
  const mapped = influences.map((influence) => {
    return {
      id: influence.id,
      name: influence.attributes.name,
      code: influence.attributes.code,
      parent_id: influence.attributes.parent_id
    }
  });
  const parents = mapped.filter((influence) => influence.parent_id === null);
  parents.forEach((parent) => {
    all_influences.push(parent);
    mapped.forEach((influence) => {
      if (influence.parent_id === parseInt(parent.id)) {
        all_influences.push(influence);
      }
    });
  });
  return all_influences;
}

export const debounce = function(text) {
  if (debounceRunning || text.length < MIN_TEXT_LENGTH) return Promise.resolve(false);
  debounceRunning = true;
  return new Promise((resolve) => {
    if (timer === null) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        debounceRunning = false;
        resolve(true);
      }, DEBOUNCE_TIME);
    }
  });
}

export const mapAudiences = function(audiences) {
  const all_audiences = audiences.map((audience) => {
    return {
      id: audience.id,
      name: audience.label
    }
  });
  return all_audiences;
}

export const mapAuthors = function(authors) {
  const all_authors = authors.map((author) => {
    return {
      id: author.id,
      name: author.attributes.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      nameOriginal: author.attributes.fullname
    }
  });
  return all_authors;
}

// export const mapLanguages = function(languages, currentLanguage) {
//   const all_languages = languages.map((language) => {
//     return {
//       id: language.id,
//       name: language[`name_${currentLanguage}`]
//     }
//   });
//   return all_languages;
// }

export const mapProviders = function(providers) {
  const all_providers = providers.map((provider) => {
    return provider.name
  });
  return all_providers;
}

export const mapImprints = function(imprints) {
  const all_imprints = imprints.map((imprint) => {
    return {
      id: imprint.id,
      name: imprint.attributes.name
    }
  });
  return all_imprints;
}

export const mapLanguages = function(languages, selectedLanguage) {
  const all_languages = languages.map((language) => {
    const langCorrected = selectedLanguage == 'en' ? language['name_en'] : language[`name_${selectedLanguage}`]
    return {
      id: language.id,
      alpha2: language.alpha2 !== null ? language.alpha2 : language.iso_639_2,
      name: langCorrected,
      nameOriginal: language.name_original,
      nonAccented: langCorrected.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    }
  });
  return all_languages.sort((a, b) => a.name.localeCompare(b.name));
}

export const mapCategories = function(categories, lang, searchStr) {
  if (!categories || categories.length == 0) return [];
  categories_mapped = [];
  categories.forEach((category) => {
    categories_mapped.push({
      code: category.attributes.code,
      name: category.attributes[`name_${lang}`],
      map: category.attributes.tree[lang]
    });
  });

  return categories_mapped.sort((a) => {
    const mapLastWord = a.map.split(' ').pop();
    return mapLastWord.toLowerCase().includes(searchStr.toLowerCase()) ? -1 : 1;
  });
}

export const obtainValues = function(arr, key) {
  return arr.map((item) => { 
    return ALL_VARIATIONS.includes(item[key]) ? 'All' : item[key];
  });
};

export const translateAll = function(allArr) {
  return allArr.map((item) => {
    return ALL_VARIATIONS.includes(item) ? 'All' : item;
  });
}

export const obtainMarkets = function(markets) {
  const all_markets = markets.map((market) => {
    return market.id
  });
  return all_markets;
};

export const preventAllRemoval = function(e, multiSelect, field, multiselect_arrays) {
  const arr = multiselect_arrays.find(item => item.name === field);
  if (ALL_VARIATIONS.includes(e) || multiSelect[field].length === 0) {
    multiSelect[field] = arr.arr;
  }
};

export const setSelectorsMargin = function() {
  selectorsIds.forEach((selectorId) => {
    const element = document.getElementById(selectorId);
    if (!element) return;
    const selector = element.getBoundingClientRect();
    if (selector.right > MULTISELECT_LEFT_MARGIN) {
      element.nextElementSibling.classList.add('menu_left');
    } else {
      element.nextElementSibling.classList.add('menu_right');
    }
  });
};

export const findLibrary = async function(
  libraryId, 
  libraryStore, 
  toRaw, 
  selected, 
  multiselect, 
  t, 
  loadedOptions
) {
  if (libraryId !== null) {
    await libraryStore.fetchCatalogPublicationLibrary(parseInt(libraryId));
    const libraryData = toRaw(libraryStore.data.data.attributes);
    selected.value.network_id = libraryData.network_id;

    // Set territory and state
    if (libraryData.territory_id && libraryData.state) {
      selected.value.territory_state = `${libraryData.territory_id.toLowerCase()}_${libraryData.state.toLowerCase()}`;
    } else if (libraryData.territory_id && libraryData.state === '') {
      selected.value.territory_state = libraryData.territory_id.toLowerCase();
    }

    // Set multiselect values for markets and connectors
    multiselect.value.markets = libraryData.markets.map(
      market => ({ name: t(`components.catalog_publication.filters.market_${market}`), id: market })
    );
    multiselect.value.connectors = libraryData.connectors.map(
      connector => ({ name: connector, id: connector })
    );

    //clear library catalog's options
    loadedOptions.value.libraries = [];
  }
}

export const dynamicSearchStandalone = async function(
  event, 
  loadedOptions,
  store, 
  toRaw,
  fetcher, 
  mapperAndLang,
  t,
  title
) {
  const loader = document.getElementById(`${title}_loader`);
  debounce(event.target.value).then(async (valid) => {
    if (!valid) return;
    loadedOptions.value = [];
    loader.innerHTML = '';
    loader.classList.add('spinner-border');
    await store[fetcher](event.target.value);
    loader.classList.remove('spinner-border');
    setTimeout(() => {
      if (mapperAndLang) {
        const mappedResults = module.exports[mapperAndLang.mapper](
          Array.from(toRaw(store.data.data)),
          mapperAndLang.lang
        );
        if (Object.keys(mappedResults).length === 0) setNotFound(loader, t)
          loadedOptions.value = mappedResults;
      } else {
        const results = Array.from(toRaw(store.data.data));
        if (Object.keys(results).length === 0) setNotFound(loader, t)
          loadedOptions.value = results;
      }
    }, 100);
  });
}

export const dynamicSearch = async function(
  event, 
  loadedOptions, 
  store, 
  toRaw, 
  loaderElement, 
  loadedOption, 
  fetcher, 
  mapperAndLang,
  t
) {
  const loader = document.getElementById(loaderElement);
  debounce(event.target.value).then(async (valid) => {
    if (!valid) return;
    loadedOptions.value[loadedOption] = [];
    loader.innerHTML = '';
    loader.classList.add('spinner-border');
    await store[fetcher](event.target.value);
    loader.classList.remove('spinner-border');
    setTimeout(() => {
      if (mapperAndLang) {
        const mappedResults = module.exports[mapperAndLang.mapper](
          Array.from(toRaw(store.data.data)),
          mapperAndLang.lang
        );
        if (Object.keys(mappedResults).length === 0) setNotFound(loader, t)
        loadedOptions.value[loadedOption] = mappedResults;
      } else {
        const results = Array.from(toRaw(store.data.data));
        if (Object.keys(results).length === 0) setNotFound(loader, t)
        loadedOptions.value[loadedOption] = results;
      }
    }, 100);
  });
}

const setNotFound = function(loader, t) {
  loader.innerHTML = `<small>${t('components.catalog_publication.filters.not_found')}</small>`;
  setTimeout(() => {
    loader.innerHTML = '';
  }, 2000);
}

export const categorySearchTrigger = async function (
  value, 
  lang, 
  toRaw, 
  loadedOptions, 
  categoriesStore,
  title
) {
  const loaderCategories = document.getElementById(`${title}_loader`);
  loaderCategories.classList.add('spinner-border');
  await categoriesStore.fetchCatalogPublicationCategories(value, lang);
  loaderCategories.classList.remove('spinner-border');
  loadedOptions.value.categories = [];
  const mappedCategories = mapCategories(
    Array.from(toRaw(categoriesStore.data.data)),
    lang,
    value
  );
  setTimeout(() => {
    loadedOptions.value.categories = mappedCategories;
  }, 100);
}

export const setDefaultValues = function(multiselect, selected, t) {
  selected.value.availability = 'All';
  selected.value.influence = 'All';
  multiselect.value.providers = [t('components.catalog_publication.filters.all')];
  multiselect.value.audiences = [{ id: null, name: t('components.catalog_publication.filters.all') }];
  multiselect.value.book_formats = [{ id: 1, name: t('components.catalog_publication.filters.all'), key: null }]
  multiselect.value.catalog_types = [{ value: 'All', name: t('components.catalog_publication.filters.all') }];
}

export const selectAllOrSeveral = (option, type, id, multiselect) => {
  if (id === null ? ALL_VARIATIONS.includes(option) : ALL_VARIATIONS.includes(option[id])) {
    multiselect[type] = multiselect[type].filter(
      (item) => ALL_VARIATIONS.includes(id === null ? item : item[id])
    );
  } else {
    multiselect[type] = multiselect[type].filter(
      (item) => !ALL_VARIATIONS.includes(id === null ? item : item[id])
    );
  }
}

export const getQueryParams = (queryParams, fromStore = false) => {
  const params = fromStore ? new URLSearchParams(queryParams) : queryParams;
  const arrayQuery = [];

  if (fromStore) {
    params.forEach((value, key) => {
      arrayQuery.push(`<b>${key}</b>= ${value};`);
    });
  } else {
    for (const key in params) {
      if (params[key] !== 'All' && params[key] !== '') {
        arrayQuery.push(`<b>${key}</b>= ${params[key]};`);
      }
    }
  }
  return arrayQuery.join(' ');
};
