/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { createRouter, createWebHistory } from 'vue-router';
import axios from '../services/ApiService';
import i18n from '../utils/i18n';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'focus-within-polyfill';
import 'scripts/confirm';
import { Modal } from 'scripts/modal';

// Material Design Modules
import {MDCTextField} from '@material/textfield';
import {MDCSelect} from '@material/select';
import {MDCMenu} from '@material/menu';

// Material Design Styles
import '../stylesheets/material-styles.scss';
import '../stylesheets/menu-actions.scss';

// Vue Components
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createBootstrap } from 'bootstrap-vue-next'
import App from '../components/App.vue';
import ExampleApp from '../components/ExampleApp.vue';
import FiltrableSelect from '../components/ui/FiltrableSelect.vue';
import SearchableSelect from '../components/ui/SearchableSelect.vue';

// Vue pages
import PageCart from '../pages/Cart.vue';
import NotFound from '../pages/NotFound.vue';
import CatalogPublication from '../pages/CatalogPublication.vue';
import CatalogPublicationSettings from '../pages/CatalogPublicationSettings.vue';
import CatalogPublicationSaveSettings from '../pages/CatalogPublicationSaveSettings.vue';


// Vue Router
const routes = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/frontend/home', component: ExampleApp },
    { path: '/carts/:id', component: PageCart },
    { path: '/advanced_search', component: SearchableSelect },
    { path: '/frontend/catalog/publications', component: CatalogPublication },
    { path: '/frontend/catalog/publications/settings', component: CatalogPublicationSettings },
    { path: '/frontend/catalog/save_settings', component: CatalogPublicationSaveSettings },
    { path: '/frontend/*', component: NotFound, name: 'notFound' }
  ]
});

// Initialization
document.addEventListener('DOMContentLoaded', (event) => {
  if (document.getElementById('vue-app')) {
    const pinia = createPinia();
    const app = createApp(App);

    app.use(routes);
    app.use(pinia);
    app.use(i18n);

    pinia.use(({ store }) => {
      store.$axios = axios
    });
    app.use(createBootstrap());
    app.mount('#vue-app');
  }

  window.initializeSearchableSelect = function() {
    document.querySelectorAll('.searchable-select').forEach(function(searchable_select) {
      var jsonData = {};
      if (searchable_select.dataset.json) {
        jsonData = JSON.parse(searchable_select.dataset.json);
      }
      const serchableSelectApp = createApp(
        SearchableSelect,
        jsonData
      );
      serchableSelectApp.use(i18n);
      serchableSelectApp.mount(searchable_select);
    });
  }

  function initializeStickyHeader () {
    window.onscroll = function() { stickyHeaderChecker() };
    var $header = document.getElementById("sticky-header");
    var sticky = $header.offsetTop;

    function stickyHeaderChecker() {
      if (window.pageYOffset > sticky) {
        $header.classList.add("sticky");
      } else {
        $header.classList.remove("sticky");
      }
    }
  }

  document.querySelectorAll('.filtrable-select').forEach(function(filtrable_select) {
    var jsonData = {};
    if (filtrable_select.dataset.json) {
      jsonData = JSON.parse(filtrable_select.dataset.json);
    }
    const filtrableSelectApp = createApp(FiltrableSelect, jsonData);
    filtrableSelectApp.use(i18n);
    filtrableSelectApp.mount(filtrable_select);
  });

  if (document.getElementById("sticky-header")) {
    initializeStickyHeader();
  }

  // Material Component Initialization
  window.x = {};
  window.x.formInitializer = function () {

    window.x.initTextField = function(textFieldEl) {
      let textField = new MDCTextField(textFieldEl);
      textFieldEl.addEventListener('input', function(event) {
        if (textField.valid) {
          textField.valid = true;
        }
      });
      return textField;
    }
    window.x.textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), window.x.initTextField);

    window.x.initSelectField = function(selectFieldEl) {
      let select = new MDCSelect(selectFieldEl);
      let input = selectFieldEl.querySelector('input');

      // Check for existing value
      if (input.value !== "") {
        select.selectedIndex =
          select
            .foundation_
            .menuItemValues_
            .findIndex(el => el === input.value);
      }

      if (input.required) { select.required = input.required }

      select.listen('MDCSelect:change', () => {
        input.value = select.value;
      });

      return select;
    }
    window.x.selectFields = [].map.call(document.querySelectorAll('.mdc-select'), window.x.initSelectField);

    const $ncbutton = document.querySelector('#nav-cart__button');
    if ($ncbutton) {
      window.x.ncmenu = new MDCMenu(
        document.querySelector('#nav-cart__menu')
      );

      $ncbutton.addEventListener('click', function(e){
        e.preventDefault();

        if (window.x.ncmenu.open) {
          window.x.ncmenu.menuSurface_.close();
          $ncbutton.setAttribute('aria-pressed', false);
        } else {
          window.x.ncmenu.menuSurface_.open();
          $ncbutton.setAttribute('aria-pressed', true);
        }
      })
    }

    const $nlbutton = document.querySelector('#nav-list__button');
    if ($nlbutton) {
      window.x.nlmenu = new MDCMenu(
        document.querySelector('#nav-list__menu')
      );

      $nlbutton.addEventListener('click', function(){
        if (window.x.nlmenu.open) {
          window.x.nlmenu.menuSurface_.close();
          $nlbutton.setAttribute('aria-pressed', false);
        } else {
          window.x.nlmenu.menuSurface_.open();
          $nlbutton.setAttribute('aria-pressed', true);
        }
      })
    }

    window.x.tsmenu = [];
    document.querySelectorAll('#topper-sort__button').forEach(function(button, index) {
      window.x.tsmenu[index] = new MDCMenu(
        document.querySelectorAll('#topper-sort__menu')[index]
      );
      button.addEventListener('click', function(){
        if (window.x.tsmenu[index].open) {
          window.x.tsmenu[index].menuSurface_.close();
          button.setAttribute('aria-pressed', false);
        } else {
          window.x.tsmenu[index].menuSurface_.open();
          button.setAttribute('aria-pressed', true);
        }
      });
    });

    const $langbutton = document.querySelector('#language-button');
    if ($langbutton) {
      window.x.langmenu = new MDCMenu(document.querySelector('#language-menu'));
      window.x.langmenu.setAnchorCorner(1);

      $langbutton.addEventListener('click', function(){
        if (window.x.langmenu.open) {
          window.x.langmenu.menuSurface_.close();
          $langbutton.setAttribute('aria-pressed', false);
        } else {
          window.x.langmenu.menuSurface_.open();
          $langbutton.setAttribute('aria-pressed', true);
          if (document.querySelector('#overlay')) {
            if (document.querySelector('#overlay').classList.contains('overlay--active')) {
              navOverlay();
            }
          }
        }
      })
    }

    const $marketbutton = document.querySelector('#market-button');
    if ($marketbutton) {
      window.x.marketmenu = new MDCMenu(document.querySelector('#market-menu'));
      window.x.marketmenu.setAnchorCorner(1);

      $marketbutton.addEventListener('click', function(){
        if (window.x.marketmenu.open) {
          window.x.marketmenu.menuSurface_.close();
          $marketbutton.setAttribute('aria-pressed', false);
        } else {
          window.x.marketmenu.menuSurface_.open();
          $marketbutton.setAttribute('aria-pressed', true);
          if (document.querySelector('#overlay').classList.contains('overlay--active')) {
            navOverlay();
          }
        }
      })
    }

    const $librarybutton = document.querySelector('#library-button');
    if ($librarybutton) {
      window.x.librarymenu = new MDCMenu(document.querySelector('#library-menu'));
      window.x.librarymenu.setAnchorCorner(1);

      $librarybutton.addEventListener('click', function(){
        if (window.x.librarymenu.open) {
          window.x.librarymenu.menuSurface_.close();
          $librarybutton.setAttribute('aria-pressed', false);
        } else {
          window.x.librarymenu.menuSurface_.open();
          $librarybutton.setAttribute('aria-pressed', true);
          if (document.querySelector('#overlay').classList.contains('overlay--active')) {
            navOverlay();
          }
        }
      })
    }

    const $territorybutton = document.querySelector('#territory-button');
    if ($territorybutton) {
      window.x.territorymenu = new MDCMenu(document.querySelector('#territory-menu'));
      window.x.territorymenu.setAnchorCorner(1);

      $territorybutton.addEventListener('click', function(){
        if (window.x.territorymenu.open) {
          window.x.territorymenu.menuSurface_.close();
          $territorybutton.setAttribute('aria-pressed', false);
        } else {
          window.x.territorymenu.menuSurface_.open();
          $territorybutton.setAttribute('aria-pressed', true);
          if (document.querySelector('#overlay').classList.contains('overlay--active')) {
            navOverlay();
          }
        }
      })
    }

    const $sessionbutton = document.querySelector('#session-button');
    if ($sessionbutton) {
      window.x.sessionmenu = new MDCMenu(document.querySelector('#session-menu'));
      window.x.sessionmenu.setAnchorCorner(1);

      $sessionbutton.addEventListener('click', function(){
        if (window.x.sessionmenu.open) {
          window.x.sessionmenu.menuSurface_.close();
          $sessionbutton.setAttribute('aria-pressed', false);
        } else {
          window.x.sessionmenu.menuSurface_.open();
          $sessionbutton.setAttribute('aria-pressed', true);
          if (document.querySelector('#overlay').classList.contains('overlay--active')) {
            navOverlay();
          }
        }
      })
    }

    // input file
    const $buttonFiles = document.querySelectorAll('.button-file');
    $buttonFiles.forEach(($buttonFile) => {
      $buttonFile.addEventListener('click', (event) => {
        event.preventDefault();
        let $file = event.target.previousElementSibling;
        if ($file) $file.click();
        return false;
      })
    });


    const $inputFiles = document.querySelectorAll('input[type="file"]');
    $inputFiles.forEach(($inputFile) => {
      $inputFile.addEventListener('change', (event) => {
        event.target.nextElementSibling.nextElementSibling.innerHTML = event.target.files[0].name;
      });
    });

    const $actionButtons = document.querySelectorAll('.menu-actions__button');
    $actionButtons.forEach(($actionButton, index) => {
      window.x.menuActions = window.x.menuActions || [];
      window.x.menuActions[index] = new MDCMenu(
        $actionButton.nextElementSibling.querySelector('.menu-actions__items')
      );

      $actionButton.addEventListener('click', function() {
        if (window.x.menuActions[index].open) {
          window.x.menuActions[index].menuSurface_.close();
          $actionButton.setAttribute('aria-pressed', false);
        } else {
          window.x.menuActions[index].menuSurface_.open();
          $actionButton.setAttribute('aria-pressed', true);
        }
      })
    });

    window.x.findSelectField = (identifier) => {
      return window.x.selectFields.find((select) => {
        return select['root_'].id == identifier;
      });
    }

    window.x.modals = [].map.call(document.querySelectorAll('.modals'), $modal => new Modal($modal));

    window.x.findModal = (identifier) => {
      return window.x.modals.find(modal => modal.identifier == identifier)
    }
  }

  window.x.formInitializer();

  function navOverlay() {
    document.getElementById('overlay-button').classList.toggle('chevron-active');
    document.getElementById('overlay').classList.toggle('overlay--active');
    document.getElementById('sub-menu').classList.toggle('sub-menu--active');
    document.getElementById('nav-below').classList.toggle('nav-below--remove-border');
    document.body.classList.toggle('noscroll');
  }
  document.querySelectorAll('.nav-overlay').forEach(function(nav) {
    nav.addEventListener('click', function() {
      navOverlay();
    });
  });

  function mobileFilterOverlay() {
    document.getElementById('filters-overlay').classList.toggle('overlay--active');
    document.body.classList.toggle('noscroll');
    document.getElementsByClassName('mobile-facets')[0].classList.toggle('mobile-facets--open')
  }
  if (document.getElementById('filters-overlay')) {
    document.getElementById('filters-overlay').addEventListener('click', function() {
      mobileFilterOverlay();
    });
  }

  document.querySelectorAll('.mobile-filters-button').forEach(function(filterButton) {
    filterButton.addEventListener('click', function() {
      mobileFilterOverlay();
    })
  });

  function resizeOverlayTrigger() {
    if (window.innerWidth > 800) {
      mobileMenuOverlay();
      window.removeEventListener("resize", resizeOverlayTrigger);
    }
  }

  function mobileMenuOverlay() {
    document.getElementById('mobile-menu-overlay').classList.toggle('overlay--active');
    document.body.classList.toggle('noscroll');
    document.querySelector('.mobile-side-menu').classList.toggle('open-sesame');
    document.querySelector('.msm__categories').classList.remove('open-sesame');
    document.querySelector('.msm__catalog').classList.remove('open-sesame');

    if (document.getElementById('mobile-menu-overlay').classList.contains('overlay--active')) {
      window.addEventListener("resize", resizeOverlayTrigger);
    } else {
      window.removeEventListener("resize", resizeOverlayTrigger);
    }
  }
  if (document.getElementById('mobile-menu-overlay')) {
    document.getElementById('mobile-menu-overlay').addEventListener('click', function() {
      mobileMenuOverlay();
    });
  }

  var $menuToggle = document.querySelector('.mobile-menu-toggle');
  if ($menuToggle) {
    $menuToggle.addEventListener('click', function(event) {
      mobileMenuOverlay();
    });
    document.querySelectorAll('.msm__close').forEach((element) => {
      element.addEventListener('click', function(event) {
        mobileMenuOverlay();
      });
    })
  }

  var $msmCategoriesButton = document.querySelector('.msm__expand-categories');
  if ($msmCategoriesButton) {
    $msmCategoriesButton.addEventListener('click', function() {
      document.querySelector('.msm__categories').classList.add('open-sesame');
    })

    document.querySelector('.msm__close-categories').addEventListener('click', function() {
      document.querySelector('.msm__categories').classList.remove('open-sesame');
    })
  }

  var $msmCatalogButton = document.querySelector('.msm__expand-catalog');
  if ($msmCatalogButton) {
    $msmCatalogButton.addEventListener('click', function() {
      document.querySelector('.msm__catalog').classList.add('open-sesame');
    })

    document.querySelector('.msm__close-catalog').addEventListener('click', function() {
      document.querySelector('.msm__catalog').classList.remove('open-sesame');
    })
  }

  if (document.querySelector('.browse__items')) {
    if (localStorage.getItem('browseItemsState') == 'list') {
      document.querySelector('.browse__items').classList.remove('browse__items--grid');
    }
    if (localStorage.getItem('browseItemsState') == 'grid') {
      document.querySelector('.browse__items').classList.add('browse__items--grid');
    }
  }

  document.querySelectorAll('.view-toggle__list').forEach(function(button) {
    button.addEventListener('click', function() {
      document.querySelector('.browse__items').classList.remove('browse__items--grid');
      localStorage.setItem('browseItemsState', 'list');
    });
  });
  document.querySelectorAll('.view-toggle__grid').forEach(function(button) {
    button.addEventListener('click', function() {
      document.querySelector('.browse__items').classList.add('browse__items--grid');
      localStorage.setItem('browseItemsState', 'grid');
    });
  });

  document.querySelectorAll('.submit-form').forEach(function(button) {
    button.addEventListener('click', function() {
      button.parentNode.parentNode.classList.add('submitting');
    });
  });

  var $menuLanguageToggle = document.querySelector('.mobile-menu-language-toggle');
  if ($menuLanguageToggle) {
    $menuLanguageToggle.addEventListener('click', function(event) {
      $menuLanguageToggle.parentElement.classList.toggle('mobile-languages--open');
    });
  }

  document.querySelectorAll('.facet-toggler').forEach(function(facetToggler) {
    facetToggler.addEventListener('click', function() {
      facetToggler.parentElement.classList.toggle('facet__group--closed');
    });
  });

  const $explanationToggle = document.getElementById('explanation-toggler');
  if ($explanationToggle) {
    $explanationToggle.addEventListener('click', function(event) {
      $explanationToggle.parentElement.classList.toggle('explanation__group--closed');
    });
  }

  if (document.querySelector('.browse-facets')) {
    var $langFacets = document.querySelector('.browse-facets').getElementsByClassName('multi-lang-active')[0];
    if ($langFacets) {
      var $all = document.querySelector('.browse-facets').querySelector('#lang-facet__all');
      var $notAll = document.querySelector('.browse-facets').querySelectorAll('.langs-not-all');

      $notAll.forEach(function($checkbox) {
        $checkbox.addEventListener('change', function() {
          if(this.checked == true) {
            $all.checked = false;
          }
        })
      })

      $all.addEventListener('change', function () {
        if(this.checked == true) {
          $notAll.forEach(function ($checkbox){
            $checkbox.checked = false;
          })
        }
      })

      var $langFacetsButton = document.querySelector('.browse-facets').querySelector('#facetLangButton');
      $langFacetsButton.addEventListener('click', function(event) {
        let langParams = [];
        let $langOptions = document.querySelector('.browse-facets').querySelectorAll('.langOption');
        $langOptions.forEach(function($checkbox) {
          if ($checkbox.checked) {
            langParams.push($checkbox.value);
          }
        })


        if (langParams.length > 0 && typeof posthog !== 'undefined') {
          var langParamsString = langParams.join('-');
          posthog.capture('catalog-facet-language-' + langParamsString);
        }

        let url = new URL($langFacetsButton.href)
        url.searchParams.delete('lang');
        url.searchParams.append('lang', langParams.join());

        $langFacetsButton.href = url.toString();
      })
    }
    

    const paramPurchased = new URLSearchParams(window.location.search).get('purchased');
    if(paramPurchased) {
      const browseFacets = document.querySelector('.browse-facets');
      const facetPurchased = document.querySelectorAll('.facet-purchased')[0]
      if(browseFacets && facetPurchased) {
        browseFacets.prepend(facetPurchased);
        document.querySelectorAll('.facet-purchased')[1].remove();
      }
    }

    const facetsPurchased = document.querySelectorAll('.facet-purchased-form');
    const facetCheckboxesIds = ['#available_in_inventory_checkbox_days_remaining', '#available_in_inventory_checkbox_loans_remaining']
    const facetInputTextIds = ['#available_in_inventory_text_days_remaining', '#available_in_inventory_text_loans_remaining']
    const verifyCheckboxes = (button, checkboxes, textFields) => {
      const buttonDisabled = checkboxes.some((checkbox, index) => checkbox.checked && textFields[index].value);
      button.disabled = !buttonDisabled;
    };

    const handleFormSubmit = (event, currentUrl) => {
      event.preventDefault();
      const form = event.target;
      const checkboxes = facetCheckboxesIds.map(selector => form.querySelector(selector));
      const textFields = facetInputTextIds.map(selector => form.querySelector(selector));

      currentUrl.searchParams.delete('days_remaining');
      currentUrl.searchParams.delete('loans_remaining');
      textFields.forEach((textField, index) => {
        if (checkboxes[index].checked && textField.value) {
          currentUrl.searchParams.set(textField.name, textField.value);
        }
      });

      window.location.href = currentUrl;
    };

    const setupFacetPurchased = (facetPurchased) => {
      const currentUrlWithParams = new URL(window.location.href);
      const button = facetPurchased.querySelector('button');
      const checkboxes = facetCheckboxesIds.map(selector => facetPurchased.querySelector(selector));
      const textFields = facetInputTextIds.map(selector => facetPurchased.querySelector(selector));

      const updateCheckboxes = () => verifyCheckboxes(button, checkboxes, textFields);

      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', updateCheckboxes);
      });

      textFields.forEach((textField) => {
        textField.addEventListener('input', () => {
          // Allow only numeric input
          textField.value = textField.value.replace(/\D/g, '');
          updateCheckboxes();
        });
      });

      updateCheckboxes();

      facetPurchased.addEventListener('submit', (event) => handleFormSubmit(event, currentUrlWithParams));
    };

    facetsPurchased.forEach(setupFacetPurchased);
   
  }

  document.querySelectorAll('.facet__more').forEach(function(moreDisplay) {
    moreDisplay.addEventListener('click', function() {
      moreDisplay.parentElement.classList.remove('facet__group--hidden-ninjas');
    });
  });

  document.querySelectorAll('.dlp__line').forEach(function(openUp) {
    openUp.addEventListener('click', function() {
      openUp.parentElement.classList.add('dlp__open-up');
    });
  });

  document.querySelectorAll('.item__ot-button').forEach(function($openUp) {
    $openUp.addEventListener('click', function(event) {
      event.preventDefault();
      let alternate = $openUp.textContent;
      $openUp.textContent = $openUp.getAttribute('data-alternate');
      $openUp.setAttribute('data-alternate', alternate);

      $openUp.parentElement.parentElement.classList.toggle('item__top-bordered');

      let table = document.querySelector(".item__table[data-offer-id='" + $openUp.getAttribute('data-offer-id') + "']");
      table.classList.toggle('item__open-up');
      table.parentElement.classList.toggle('item__bottom-bordered');
    });
  });

  document.querySelectorAll('.editable-invoice-status').forEach(($form, i) => {
    $form.querySelector('.invoice-status-select').addEventListener('change', (event) => {
      $form.classList.remove('show-success', 'show-failure');
      $form.classList.add('show-spinner');

      let url = $form.getAttribute('action');
      let payload = {
        invoice_status: event.target.value
      };
      new Ajax().patch(url, { data: payload },
        function(data) {
          $form.classList.remove('show-spinner');
          $form.classList.add('show-success');
        },
        function(error) {
          $form.classList.remove('show-spinner');
          $form.classList.add('show-failure');

          alert(error['message']);
        }
      );
    });
  });

  function loadStates() {
    let $stateSelect = document.getElementById('state-ul');
    if (!$stateSelect) {
      return;
    }

    let mdcCountry = window.x.selectFields[0];

    mdcCountry.listen('MDCSelect:change', () => {
      let mdcState = window.x.selectFields[1];
      let iso3 = mdcCountry.value;
      mdcState.value = '';

      if (["USA", "CAN"].includes(iso3)) {
        fetch("/user/state_from_country/" + iso3 + ".json?lang=" + I18n.locale )
          .then(function(response) {
            if (response.ok) {
              $stateSelect.replaceChildren('');
              response.json().then(function(data) {

                data.forEach(state => {
                  let $li = document.createElement("li");
                  $li.setAttribute('role', 'option');
                  $li.className = "mdc-list-item";
                  $li.innerText = state[0];
                  $li.setAttribute('data-value', state[1]);

                  $stateSelect.append($li);
                })
              })

              mdcState.disabled = false;
              mdcState.required = true;

              window.x.selectFields[1].destroy();
              window.x.selectFields[1] = window.x.initSelectField(
                document.querySelector('#mdc-state-select')
              );
            }
          })
      } else {
        mdcState.disabled = true;
        mdcState.required = false;
      }
    });
  }
  loadStates();
  window.initializeSearchableSelect();
});
